.accordionDetails {
    padding: 10px 10px 5px 20px;
}

.accordionDetails:hover {
    text-decoration: underline;
}

.typographyText {
    color: #054982;
    cursor: pointer; 
}

.typographyTextUnderline {
    text-decoration: underline;
    color: #054982;
    cursor: pointer;
}

.summaryBackground {
    background: #054982;
}
