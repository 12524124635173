.menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    position: relative;
}
.menuItemConsalt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}

.menuItem:hover {
    cursor: pointer;
    text-decoration: underline;
}

.menuItemText:hover {
    cursor: pointer;
    text-decoration: underline;
}
